import { slugify } from './slugify';
import { FlyerItemSummaryJSON } from './search';
import uniqid from 'uniqid';
import { minBy } from 'lodash-es';
import { fixImageUrl, getDataByPostalCode, LinkData, Locale } from './common';
import { FlyerItemJSON, FlyerJSON, FlyerMetadataJSON, ItemJSON } from '../definitions/BackflippDefinitions';
import { Flyer } from '../definitions/FlyerDefinitions';
import { getFlyerLink } from './links';
import { getCityData } from './location';
import { extractUniqStoresFromFlyers } from './store';
import { chunks } from './api';

export interface FlyerViewData {
  id: number;
  name: string;
  thumbnail_url: string;
  available_from: string;
  available_to: string;
  valid_to: string;
  merchant: string;
}

export interface FlyerItemViewData {
  id: string;
  name: string;
  thumbnail_url: string;
  brand?: string;
  description?: string;
  sale_story?: string;
  current_price?: string;
  original_price?: string;
  pre_price_text?: string;
  price_text?: string;
  valid_from?: number;
  valid_to?: number;
  sku?: string;
}

export interface MerchantViewData {
  name: string;
  thumbnail_url: string;
}

export interface FlyerDetailsViewData {
  flyer: FlyerViewData;
  items: FlyerItemViewData[];
}

export type FlyerFilter = 'featured' | 'latest' | 'alphabetical';

export async function getFlyerDetailsById(
  flyer: FlyerMetadataJSON,
  postalCode: string,
  locale: string,
): Promise<FlyerJSON> {
  const url = `${process.env.NEXT_PUBLIC_FLIPPBACK_BF}/flipp/flyers/${flyer.id}?locale=${locale}&postal_code=${postalCode}`;
  const now = Date.now();
  const validTo = new Date(flyer.valid_to).getTime();
  const revalidate = (validTo - now) / 1000;
  try {
    const res = await fetch(url, {
      next: { revalidate },
    });
    const data = await res.json();
    return {
      items: data.items,
    };
  } catch (e) {
    return null;
  }
}

export async function getFlyerItemDetailsById(
  itemId: number,
  flyers: FlyerMetadataJSON[],
  postalCode: string,
  locale: string,
): Promise<ItemJSON> {
  const flyer = minBy(flyers, (f: FlyerMetadataJSON) => {
    return new Date(f.valid_to).getTime();
  });
  const now = Date.now();
  const validTo = new Date(flyer.valid_to).getTime();
  const revalidate = (validTo - now) / 1000;
  const url = `${process.env.NEXT_PUBLIC_FLIPPBACK_BF}/flipp/items/${itemId}?locale=${locale}&postal_code=${postalCode}`;
  try {
    const res = await fetch(url, {
      next: { revalidate },
    });
    const data = await res.json();
    return {
      item: data.item,
    };
  } catch (e) {
    return null;
  }
}

export async function getFlyersForLocation(
  location: string,
  locale: string,
): Promise<{ flyers: FlyerMetadataJSON[]; postalCode: string }> {
  const { fallback_postal_code } = await getCityData(location as string);
  const data = await getDataByPostalCode(fallback_postal_code, locale);
  return { flyers: data.flyers, postalCode: fallback_postal_code };
}

function getFlyerDetailsClosure(postalCode, locale) {
  return function (flyer) {
    return getFlyerDetailsById(flyer, postalCode, locale);
  };
}

export async function getFlyersItems(
  flyers: FlyerMetadataJSON[],
  postalCode: string,
  locale: string,
): Promise<FlyerItemJSON[]> {
  const itemsData = await chunks(flyers, getFlyerDetailsClosure(postalCode, locale));
  return itemsData.reduce((acc, item) => {
    return item ? acc.concat(item.items) : acc;
  }, []);
}

export async function getStoresForLocation(location: string, locale: string): Promise<string[]> {
  const { flyers } = await getFlyersForLocation(location, locale as string);
  const storeNames = extractUniqStoresFromFlyers(flyers.map((f) => new Flyer(f)));
  return storeNames.map((s) => slugify(s.name));
}

export function getFlyersDataFromSearchResults(
  flyersFromSearch: FlyerItemSummaryJSON[],
  allFlyers: Flyer[],
  cityState: string,
  locale: Locale,
): LinkData[] {
  return flyersFromSearch.reduce((acc, item) => {
    const flyer = allFlyers.find((f) => {
      return f.id === item.id;
    });
    if (flyer) {
      acc.push({
        id: uniqid(),
        name: flyer.name,
        imageUrl: flyer.thumbnailPremium,
        link: getFlyerLink(flyer, locale, cityState),
        payload: flyer,
      });
    }
    return acc;
  }, [] as LinkData[]);
}

export function getFlyerName(flyer: Flyer): string {
  return `${flyer.merchant} ${flyer.name}`;
}

export const getFlyerSuffix = (locale: string) => {
  if (locale === 'en-us') {
    return 'weekly_ad';
  } else if (locale === 'fr-ca') {
    return 'circulaire';
  }
  return 'flyer';
};

export function sortFlyers(flyers: Flyer[]): Flyer[] {
  return flyers.sort((lhs, rhs) => {
    if (lhs.premium && !rhs.premium) return -1;
    if (!lhs.premium && rhs.premium) return 1;
    if (lhs.priority < rhs.priority) return -1;
    if (lhs.priority > rhs.priority) return 1;

    return lhs.validFrom.getTime() - rhs.validFrom.getTime();
  });
}

export function filterFlyers(
  flyers: Flyer[],
  category: string,
  filter: FlyerFilter,
): {
  premiumFlyers: Flyer[];
  normalFlyers: Flyer[];
} {
  if (filter === 'latest') {
    const normalFlyers = flyers
      .filter((flyer) => flyer.categories.indexOf(category) >= 0)
      .sort((lhs, rhs) => (lhs.availableFrom > rhs.availableFrom ? -1 : 1));
    return { premiumFlyers: [], normalFlyers };
  } else if (filter === 'alphabetical') {
    const normalFlyers = flyers
      .filter((flyer) => flyer.categories.indexOf(category) >= 0)
      .sort((lhs, rhs) => {
        const rhsTitle = `${rhs.merchant} ${rhs.name}`;
        const lhsTitle = `${lhs.merchant} ${lhs.name}`;
        return lhsTitle.localeCompare(rhsTitle, undefined, {
          sensitivity: 'base',
        });
      });
    return { premiumFlyers: [], normalFlyers };
  } else {
    const premiumFlyers = flyers.filter((flyer) => flyer.premium && flyer.categories.indexOf(category) >= 0);
    const normalFlyers = flyers.filter((flyer) => !flyer.premium && flyer.categories.indexOf(category) >= 0);
    return { premiumFlyers, normalFlyers };
  }
}

export function getFlyerDetailsViewData(
  flyerId: string,
  rawData: any,
  merchant: MerchantViewData,
): FlyerDetailsViewData {
  const data = rawData as FlyerJSON;
  const flyer = {
    id: Number(flyerId),
    name: data.flyer.name,
    thumbnail_url: data.flyer.premium ? data.flyer.premium_thumbnail_url : data.flyer.thumbnail_url,
    available_from: new Date(data.flyer.available_from).getTime().toString(),
    available_to: new Date(data.flyer.available_to).getTime().toString(),
    valid_to: new Date(data.flyer.valid_to).getTime().toString(),
    merchant: data.flyer.merchant,
  };
  flyer.thumbnail_url = fixImageUrl(flyer.thumbnail_url);
  const items = data.items.map((item) => {
    const thumbnail_url = fixImageUrl(item.cutout_image_url);
    return {
      id: item.id.toString(),
      name: `${merchant.name} ${flyer.name}`,
      thumbnail_url,
    };
  });

  return {
    flyer,
    items,
  };
}

export function getFlyerItemViewData(rawData: any): FlyerItemViewData {
  const { item } = rawData as ItemJSON;
  const itemData = {
    id: item.id.toString(),
    name: item.name,
    thumbnail_url: item.cutout_image_url ? item.cutout_image_url : item.image_url,
    valid_from: new Date(item.flyer_valid_from).getTime(),
    valid_to: new Date(item.flyer_valid_to).getTime(),
    brand: item.brand ? item.brand : '',
    description: item.description ? item.description : '',
    sale_story: item.sale_story ? item.sale_story : '',
    current_price: item.current_price ? item.current_price : '',
    original_price: item.original_price ? item.original_price : '',
    pre_price_text: item.pre_price_text ? item.pre_price_text : '',
    price_text: item.price_text ? item.price_text : '',
    sku: item.sku ? item.sku : '',
  };
  itemData.thumbnail_url = fixImageUrl(itemData.thumbnail_url);

  return itemData;
}

export function findMerchantFlyers(merchantNames: string[], flyers): Flyer[] {
  const names = [...merchantNames];
  while (names.length > 0) {
    const merchantName = names.pop();
    const merchantFlyers = flyers.filter((flyer) => {
      const flyerMerchantName = slugify(flyer.merchant);
      return flyerMerchantName === merchantName || merchantName.includes(slugify(flyerMerchantName));
    });
    if (merchantFlyers.length > 0) {
      return merchantFlyers;
    }
  }
  return [];
}

export function isExpired(flyer: FlyerMetadataJSON): boolean {
  const now = Date.now();
  const validTo = new Date(flyer.valid_to).getTime();
  return validTo - now <= 0;
}
