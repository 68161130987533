import { DisplayType, FlyerItemJSON, TrackingUrlJSON } from './BackflippDefinitions';
import { normalizeDate } from '../helpers/date';

export class Flyer {
  availableFrom: Date;
  availableTo: Date;
  budgetId: number;
  categories: string[];
  displayType: string;
  flyerRunId: number;
  flyerTypeId: number;
  height: number;
  id: number;
  merchant: string;
  merchantId: number;
  merchantLogo: string;
  name: string;
  path: string;
  postalCode: string;
  premium: boolean;
  priority: number;
  resolutions: number[];
  thumbnail: string;
  thumbnailPremium: string;
  validFrom: Date;
  validTo: Date;
  webIndexed: boolean;
  width: number;
  isStoreSelect: boolean;
  isTombstone: boolean;
  items: FlyerItem[];
  analyticsPayload: string;
  trackingUrls: TrackingUrl[];
  city_state: string | null;

  constructor(json: any) {
    this.availableFrom = normalizeDate(json.available_from);
    this.availableTo = normalizeDate(json.available_to, true);
    this.budgetId = json.budget_id;
    this.categories = json.categories;
    this.flyerRunId = json.flyer_run_id;
    this.flyerTypeId = json.flyer_type_id;
    this.height = json.height;
    this.id = json.id;
    this.merchant = json.merchant;
    this.merchantId = json.merchant_id;
    this.merchantLogo = json.merchant_logo;
    this.name = json.name;
    this.path = json.path;
    this.postalCode = json.postal_code;
    this.premium = json.premium;
    this.priority = json.priority;
    this.resolutions = json.resolutions;
    this.thumbnail = json.thumbnail_url;
    this.thumbnailPremium = json.premium_thumbnail_url;
    this.validFrom = normalizeDate(json.valid_from);
    this.validTo = normalizeDate(json.valid_to, true);
    this.webIndexed = json.web_indexed;
    this.width = json.width;
    this.isStoreSelect = json.is_store_select;
    this.isTombstone = false;
    this.displayType = json.display_type;
    this.analyticsPayload = json.analytics_payload;
    this.trackingUrls = json.tracking_urls
      ? json.tracking_urls.map((tracking_url_json: TrackingUrlJSON) => new TrackingUrl(tracking_url_json))
      : [];
    this.city_state = json.city_state;
  }
}

export class TrackingUrl {
  eventType: number;
  url: string;

  constructor(json: TrackingUrlJSON) {
    this.eventType = json.event_type;
    this.url = json.url;
  }
}

export class FlyerItem {
  id: number;
  flyerId: number;
  shortName: string;
  brand: string;
  name: string;
  price: string;
  displayType: DisplayType;
  discount: number;
  left: number;
  bottom: number;
  right: number;
  top: number;
  validFrom: string;
  validTo: string;
  availableTo: string;
  videoUrl: string;
  textAreas: {
    id: number;
    left: number;
    bottom: number;
    right: number;
    top: number;
  }[];
  cutoutImageUrl: string;
  pageDestination: number;
  printId: string;

  json: FlyerItemJSON;
  flyerName: string;
  merchantName: string;

  constructor(json: FlyerItemJSON, flyerName: string, merchantName: string) {
    this.id = json.id;
    this.flyerId = json.flyer_id;
    this.shortName = json.short_name;
    this.brand = json.brand;
    this.name = json.name;
    this.price = json.price;
    this.displayType = json.display_type;
    this.discount = json.discount;
    this.left = json.left;
    this.bottom = json.bottom;
    this.right = json.right;
    this.top = json.top;
    this.validFrom = json.valid_from;
    this.validTo = json.valid_to;
    this.availableTo = json.available_to;
    this.videoUrl = json.video_url;
    this.textAreas = json.text_areas;
    this.cutoutImageUrl = json.cutout_image_url;
    this.pageDestination = json.page_destination;
    this.printId = json.print_id;
    this.json = json;
    this.flyerName = flyerName;
    this.merchantName = merchantName;
  }
}

export interface FlyerData {
  flyer: any;
  merchant: {
    translations: string[];
  };
  flyerRun: any;
  flyerType: any;
  items: any[];
}
