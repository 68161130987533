import { NextRouter } from 'next/dist/shared/lib/router/router';

export const LOCATION_FOR_BOT = {
  'en-ca': {
    postalCode: 'M5R3N5',
    location: 'toronto-on',
  },
  'fr-ca': {
    postalCode: 'H2G1V4',
    location: 'montreal-qc',
  },
  'en-us': {
    postalCode: '10001',
    location: 'new-york-ny',
  },
};

interface ILocation {
  location: string;
  postalCode: string;
}

export default function useLocationDetect(router: NextRouter): ILocation {
  const location = router.query['location']
    ? (router.query['location'] as string)
    : LOCATION_FOR_BOT[router.locale].location;
  return { location, postalCode: LOCATION_FOR_BOT[router.locale].postalCode };
}
