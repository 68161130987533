import { Flyer } from '../definitions/FlyerDefinitions';
import { slugify } from './slugify';
import { findMerchantFlyers, getFlyerSuffix } from './flyers';
import { NextRouter } from 'next/dist/shared/lib/router/router';
import { FlyerMetadataJSON } from '../definitions/BackflippDefinitions';
import { Locale } from './common';
import { getAllStores } from './store';

export function getFlyerLink(flyer: Flyer, locale: Locale, location: string): string {
  if (!flyer) return '';
  const now = new Date();
  const suffix = getFlyerSuffix(locale);
  if (flyer.availableTo < now || flyer.validTo < now) {
    return getFlyersLink(locale, location);
  }
  return `/${locale}/${location}/${suffix}/${flyer.id}-${slugify(flyer.merchant)}-${slugify(flyer.name)}`;
}

export function getFlyerUrl(flyer: Flyer, locale: Locale, location: string): string {
  return `${process.env.FLIPP_WEB_HOST}${getFlyerLink(flyer, locale, location)}`;
}

export async function getFlyerRedirectLink(
  locale: Locale,
  location: string,
  flyers: FlyerMetadataJSON[],
  id: string,
): Promise<string> {
  const merchantNames = extractMerchantNamesFromSlug(id);
  const merchantFlyers = findMerchantFlyers(merchantNames, flyers);
  if (merchantFlyers.length === 0) {
    const data = await getAllStores(locale);
    const store = data.merchants.find((m) => {
      const merchantName = slugify(m.name);
      const c = merchantNames.find((mn) => {
        return mn.includes(merchantName) || mn.includes(slugify(merchantName));
      });
      return Boolean(c);
    });
    const storeName = store ? slugify(store.name) : merchantNames[0] ? merchantNames[0] : '';
    return `/${locale}/${location}/stores/${storeName}`;
  }

  const flyer = merchantFlyers.pop();
  return getFlyerLink(flyer, locale, location);
}

export function getFlyersLink(locale: string, location: string): string {
  if (!locale) return '/';
  const suffix = getFlyerSuffix(locale);
  return location ? `/${locale}/${location}/${suffix}s` : `/${locale}/${suffix}s`;
}

export function getFlyerItemLink(id: string, name: string, location: string, locale: Locale): string {
  return `/${locale}/${location}/item/${id}-${slugify(name)}`;
}

export function getFlyerItemUrl(id: string, name: string, location: string, locale: Locale): string {
  return `${process.env.FLIPP_WEB_HOST}${getFlyerItemLink(id, name, location, locale)}`;
}

export function getCouponsLink(locale: string, location: string): string {
  if (!locale) return '/';
  return location ? `/${locale}/${location}/coupons` : `/${locale}/coupons`;
}

export function getMerchantUrl(merchantName: string, locale: string): string {
  return `${process.env.FLIPP_WEB_HOST}/${locale}/stores/${slugify(merchantName)}`;
}

export function getCityMerchantLink(merchantName: string, location: string, locale: string): string {
  return `/${locale}/${location}/stores/${merchantName}`;
}

export function getAmpUrl(router: NextRouter): string {
  return `${process.env.FLIPP_WEB_HOST}/amp/${router?.locale}${router?.asPath}`;
}

export function getHrefUrl(router: NextRouter, locale: string): string {
  const link = `/${locale}${router?.asPath}`;
  if (locale === 'fr-ca') {
    if (link.includes('/flyer/')) {
      return `${process.env.FLIPP_WEB_HOST}${link.replace('/flyer/', '/circulaire/')}`;
    }
    if (link.includes('/flyers')) {
      return `${process.env.FLIPP_WEB_HOST}${link.replace('/flyers', '/circulaires')}`;
    }
  }
  return `${process.env.FLIPP_WEB_HOST}${link}`;
}

function extractMerchantNamesFromSlug(flyerIdAndName: string): string[] {
  const names = [];
  const flyerIdAndNameSplitted = flyerIdAndName.split('-');
  flyerIdAndNameSplitted.shift();

  while (flyerIdAndNameSplitted.length > 0) {
    const name = flyerIdAndNameSplitted.reduce(
      (result, value) => (result.length === 0 ? `${value}` : `${result}-${value}`),
      '',
    );
    names.push(name);
    flyerIdAndNameSplitted.pop();
  }

  return names;
}
