import { uniqBy, orderBy, upperFirst } from 'lodash-es';
import { Flyer } from '../definitions/FlyerDefinitions';
import { slugify } from './slugify';
import { sortFlyers } from './flyers';
import { fromJsDate } from './date';
import { DataJSON } from '../definitions/BackflippDefinitions';
import { LinkData } from './common';
import { CityLocation } from './location';

export interface StoreCityRouteProps {
  data: DataJSON;
  postalCode: string;
  location: string;
  cities: LinkData[];
  merchants: LinkData[];
  storeName: string;
  seoContent: string;
  topCities: CityLocation[];
}

export interface Store {
  id: string;
  name: string;
  logo: string;
}

export function getDisplayStoreName(store: string): string {
  return store
    .split('-')
    .map((item) => upperFirst(item))
    .join(' ');
}

export function extractUniqStoresFromFlyers(flyers: Flyer[]): Store[] {
  const stores = uniqBy(flyers, 'merchant').map((flyer) => ({
    id: flyer.merchantId,
    name: flyer.merchant,
    logo: flyer.merchantLogo,
  }));
  return orderBy(stores, (store) => store.name.toLowerCase(), ['asc']);
}

export function extractStoreFlyers(flyers: Flyer[], storeName: string): Flyer[] {
  return flyers.filter((f) => {
    return slugify(f.merchant) === storeName;
  });
}

export interface StoreFlyers {
  items: Flyer[];
  count: number;
}

export const POPULAR_FLYERS_COUNT = 8;

export function splitStoreFlyers(
  flyerList: Flyer[],
  storeName: string,
  nowDate: Date,
): {
  featuredFlyer: Flyer;
  currentFlyers: StoreFlyers;
  upcomingFlyers: StoreFlyers;
  popularFlyers: Flyer[];
} {
  const flyers = extractStoreFlyers(flyerList, storeName);
  const premiumFlyers = flyers.filter((f) => f.premium === true);
  const normalFlyers = flyers.filter((f) => f.premium === false);
  const sortedFlyers = [...orderBy(premiumFlyers, ['validFrom']), ...orderBy(normalFlyers, ['validFrom'])];
  const featuredFlyer = sortedFlyers[0];
  const now = fromJsDate(nowDate);
  const currentFlyers = { items: [], count: 0 };
  const upcomingFlyers = { items: [], count: 0 };
  let arr, from;
  sortedFlyers.map((f) => {
    from = fromJsDate(f.validFrom);
    if (from > now) {
      arr = upcomingFlyers.items;
      upcomingFlyers.count += 1;
    } else {
      arr = currentFlyers.items;
      currentFlyers.count += 1;
    }
    if (f.id !== featuredFlyer.id) {
      arr.push(f);
    }
  });
  const popularFlyers = sortFlyers(flyerList)
    .filter((f) => slugify(f.merchant) !== storeName)
    .slice(0, POPULAR_FLYERS_COUNT);
  return { featuredFlyer, currentFlyers, upcomingFlyers, popularFlyers };
}

export interface StoresJSON {
  merchants: [
    {
      id: number;
      name: string;
      us_based: boolean;
      name_identifier: string;
    },
  ];
}

export async function getAllStores(locale: string): Promise<StoresJSON> {
  const url = `${process.env.NEXT_PUBLIC_FLIPPBACK_API}/merchants?locale=${locale}`;
  try {
    const res = await fetch(url);
    return await res.json();
  } catch (e) {
    return null;
  }
}
