import { DateTime, Duration } from 'luxon';
import { Translate } from 'next-translate';

const TIME_ZONE = 'America/New_York';

export function normalizeDate(str: string, endOfDay = false): Date {
  return convertTimeToZulu(str, endOfDay);
}

export function friendlyValidity(t: Translate, from: Date, to: Date) {
  const nowDay = fromJsDate(new Date());
  const fromDay = fromJsDate(from);
  const untilDay = fromJsDate(to);

  if (nowDay < fromDay) {
    const duration = getDuration(fromDay, nowDay);
    const days = Number(getExactDuration(duration, 'days'));
    const isSameDay = days < 1 && nowDay.day === fromDay.day;
    const isNextDay = days < 2 && Math.abs(nowDay.weekday - fromDay.weekday) === 1;
    if (isSameDay) {
      return t('starts_today', {});
    } else if (isNextDay) {
      return t('starts_tomorrow', {});
    } else if (days < 7) {
      return t('starts_on_day', {
        day: formatDate(from, 'EEEE'),
      });
    } else {
      return t('starts_on_date', {
        date: formatDate(from, 'MMM d, y'),
      });
    }
  } else if (nowDay < untilDay) {
    const duration = getDuration(untilDay, nowDay);
    const years = Number(getExactDuration(duration, 'years'));
    const months = Number(getExactDuration(duration, 'months'));
    const days = Number(getExactDuration(duration, 'days'));
    const weeks = days / 7;

    if (years > 1) {
      if (years < 2) {
        return t('1_year_left', {});
      } else {
        return t('n_years_left', {
          n: Math.floor(years).toString(),
        });
      }
    } else if (months > 1) {
      if (months < 2) {
        return t('1_month_left', {});
      } else {
        return t('n_months_left', {
          n: Math.floor(months).toString(),
        });
      }
    } else if (weeks > 1) {
      if (weeks < 2) {
        return t('1_week_left', {});
      } else {
        return t('n_weeks_left', {
          n: Math.floor(days / 7).toString(),
        });
      }
    } else {
      const isSameDay = days < 1 && nowDay.day === untilDay.day;
      const isNextDay = days < 2 && Math.abs(nowDay.weekday - untilDay.weekday) === 1;
      if (isSameDay) {
        return t('ends_today', {});
      } else if (isNextDay) {
        return t('until_tomorrow', {});
      } else {
        return t('until_day', {
          day: formatDate(to, 'EEEE'),
        });
      }
    }
  } else {
    return t('ended_on_date', {
      date: formatDate(to, 'MMM d, y'),
    });
  }
}

function getNow(): DateTime {
  return DateTime.local();
}

export function fromJsDate(jsDate: Date): DateTime {
  return DateTime.fromJSDate(jsDate, { zone: TIME_ZONE });
}

function formatDate(date: Date, formatStr: string): string {
  return DateTime.fromJSDate(date, { zone: TIME_ZONE }).toFormat(formatStr);
}

function getDuration(firstDate: DateTime, lastDate: DateTime): Duration {
  return firstDate.diff(lastDate);
}

function getExactDuration(durationObj: any, unit: any): Duration {
  return durationObj.as(unit);
}

export function convertTimeToZulu(dateStr?: string, endOfDay = false): Date {
  if (!dateStr) return new Date();

  const dateStrShort = dateStr && dateStr.split('T'); // "2021-06-20T23:59:59-04:00"
  const shortDate = dateStrShort[0];
  const ymd = shortDate.split('-');
  const year = Number(ymd[0]);
  const month = Number(ymd[1]) - 1;
  const day = Number(ymd[2]);
  const hours = endOfDay ? 23 : 0;
  const minutes = endOfDay ? 59 : 0;
  const seconds = endOfDay ? 59 : 0;
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

export function ll(date: Date) {
  if (!date) return null;
  return formatDate(date, 'MMM d, y');
}

export function getCurrentYear() {
  return getNow().year;
}

export function convertTimestampToDate(timestamp: string, formatStr = 'dd MMMM'): string {
  return formatDate(new Date(Number(timestamp)), formatStr);
}

export function convertTimestampToISODate(timestamp: string): string {
  return DateTime.fromMillis(Number(timestamp), { zone: TIME_ZONE }).toISO();
}
