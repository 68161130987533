import keyMirror from 'keymirror';

const AppConstants = {
  PRODUCTION_HOSTNAME: 'flipp.com',

  MOBILE_APP_LINK_FOOTER: 'https://click.flipp.com/h8pE/jc7w6nh9',
  MOBILE_APP_LINK_BUTTON: 'https://click.flipp.com/h8pE/8754848d',

  ActionTypes: keyMirror({
    RECEIVE_SERVER_TIME: null,
    RECEIVE_REFERRAL_CODE: null,

    REQUEST_FLYER_INFO: null,
    RECEIVE_FLYER_INFO: null,
    REQUEST_FLYER_LIST: null,
    RECEIVE_FLYER_LIST: null,

    RECEIVE_BANNER_IMAGE_SIZES: null,

    REQUEST_OPENINGS_LIST: null,
    RECEIVE_OPENINGS_LIST: null,

    REQUEST_OPENINGS_DETAILS: null,
    RECEIVE_OPENINGS_DETAILS: null,

    REQUEST_PAGE_LIST: null,
    RECEIVE_PAGE_LIST: null,

    REQUEST_ITEM_LIST: null,
    RECEIVE_ITEM_LIST: null,

    REQUEST_MULTI_SKU_ITEM_LIST: null,
    RECEIVE_MULTI_SKU_ITEM_LIST: null,

    REQUEST_ITEM_SEARCH: null,
    RECEIVE_ITEM_SEARCH: null,
    RETRY_SEARCH: null,

    REQUEST_STORE_LOCATION_LIST: null,
    RECEIVE_STORE_LOCATION_LIST: null,
    SELECT_STORE_LOCATION: null,

    UPDATE_ZOOM_LEVEL: null,
    RESET_ZOOM_LEVEL: null,

    TOGGLE_SCROLL_BLOCK: null,
    UPDATE_SCROLL_VALUE: null,
    UPDATE_MAX_SCROLL_VALUE: null,
    UPDATE_SCROLL_SNAP_POINTS: null,

    SCRUB_BAR_INTERACTION_START: null,
    SCRUB_BAR_INTERACTION: null,
    SCRUB_BAR_INTERACTION_STOP: null,

    REQUEST_GEOLOCATION_INFO: null,
    RECEIVE_GEOLOCATION_INFO: null,

    REQUEST_GEOCODE: null,
    RECEIVE_GEOCODE: null,

    COMMIT_GEOLOCATION: null,
    REJECT_GEOLOCATION: null,

    CREATE_NEW_DEVICE: null,
    RECEIVE_DEVICE: null,

    CREATE_NEW_USER: null,
    CREATE_OR_LOGIN_USER: null,
    CREATE_OR_LOGIN_USER_SUCCESS: null,
    RECEIVE_USER: null,

    REQUEST_EMAIL_LOGIN: null,
    EMAIL_LOGIN_REQUEST_SUCCESS: null,

    REQUEST_FACEBOOK_LOGIN: null,
    RECEIVE_FACEBOOK_LOGIN_STATUS: null,

    REQUEST_APPLE_LOGIN: null,
    RECEIVE_APPLE_LOGIN_STATUS: null,

    REQUEST_GOOGLE_LOGIN: null,
    RECEIVE_GOOGLE_AUTH_RESPONSE: null,

    REQUEST_USER_PROVIDER_LIST: null,
    RECEIVE_USER_PROVIDER_LIST: null,

    REQUEST_USER_INFORMATION: null,
    RECEIVE_USER_INFORMATION: null,

    REQUEST_USER_NAME_UPDATE: null,
    RECEIVE_USER_NAME: null,

    RECEIVE_FAVOURITE_STORES: null,
    RECEIVE_UNSENT_DATA_FAVOURITE_STORES: null,

    CLEAR_AUTH: null,

    REQUEST_SHOPPING_LIST: null,
    RECEIVE_SHOPPING_LIST: null,
    RECEIVE_UNSENT_DATA_SHOPPING_LIST: null,

    REQUEST_OPT_IN: null,
    SUCCESS_OPT_IN: null,

    REQUEST_CATEGORIES: null,
    RECEIVE_CATEGORIES: null,

    // i18n
    // ----

    TOGGLE_LOCALE: null,

    // Reebee logging
    // --------------

    VISIBILITY_STATE_CHANGED: null,

    SESSION_START: null,
    SESSION_UPDATE: null,

    OPEN_FLYER: null,
    OPEN_CONTEST: null,

    OPEN_PAGE: null,

    OPEN_ITEM: null,

    ITEM_IMPRESSION: null,
    ITEM_ACTION: null,
    ITEM_MANUAL_ACTION: null,
    STORE_ACTION: null,

    ITEM_SEARCH: null,

    RECEIVE_UNSENT_LOGS: null,
    CLEAR_UNSENT_ITEM_LOGS: null,
    CLEAR_UNSENT_ITEM_MANUAL_LOGS: null,
    CLEAR_UNSENT_STORE_LOGS: null,
    // Error handling
    // --------------

    APP_ERROR: null,
    CLEAR_APP_ERRORS: null,

    RESET: null,
  }),

  PayloadSources: keyMirror({
    SERVER_ACTION: null,
    WEBSOCKET_ACTION: null,
    VIEW_ACTION: null,
  }),

  DeviceSessionTypes: keyMirror({
    REEBEE_SESSION: null,
    EMBED_SESSION: null,
  }),

  KeyCodes: {
    BACKSPACE: 8,
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    TAB: 9,
  },

  StoreAction: {
    FAVOURITE: 'favourite',
    UNFAVOURITE: 'unfavourite',
  },

  SyncInterval: {
    ANONYMOUS: 600000,
    AUTHORIZED: 10000,
  },

  ItemType: {
    ITEM: 'item',
    ITEM_MANUAL: 'itemManual',
  },

  SyncAction: {
    CHECK: 'check',
    UNCHECK: 'uncheck',
    DELETE: 'delete',
    ADD: 'add',
    EDIT_NOTE: 'editNote',
    EDIT_TITLE: 'editTitle',
    EDIT_QUANTITY: 'editQuantity',
  },

  ITEM_MANUAL_MAX_LENGTH: 200,
  ITEM_NOTES_LENGTH: 200,

  MAX_LOG_TIMEOUT: 90000,

  SMART_BANNER_DAYS_BLOCK: 7,

  FLYER_LIST_INTERVAL: 300000,
  CATEGORY_LIST_INTERVAL: 300000,

  BOOKSHELF_BASE_RENDER_SHELVES: 4,

  SearchLazyLoading: {
    baseRenderItems: 12,
    itemHeight: 90,
  },

  DownloadAppSourceIds: {
    SMART_BANNER: 90,
    MARKETING_PAGE: 91,
  },

  MAX_VIEWED_FLYERS_NUMBER: 150,

  URLStuffing: {
    ApplicationGeneral: {
      APP_VERSION: '<APPVERSION>',
      DEVICE_ID: '<DEVICEID>',
      DEVICE_SESSION_ID: '<DEVICESESSIONID>',
      DEVICE_TYPE_ID: '<DEVICETYPEID>',
      FLYER_IMAGE_WIDTH: '<FLYERIMAGEWIDTH>', // is not supported
      IMAGE_WIDTH: '<IMAGE_WIDTH>', // is not supported
      LANGUAGE_CODE: '<LANGUAGECODE>',
      LANGUAGE_ID: '<LANGUAGEID>',
      SYSTEM_VERSION: '<SYSTEMVERSION>',
    },
    ApplicationUser: {
      USER_EMAIL: '<USEREMAIL>',
      USER_GROUP_ID: '<USERGROUPID>',
      USER_ID: '<USERID>',
      USER_NAME: '<USERNAME>',
      USER_SESSION_ID: '<USERSESSIONID>',
    },
    Location: {
      CITY: '<CITY>',
      COUNTRY: '<COUNTRY>',
      COUNTRY_ABBR: '<COUNTRYABBR>',
      LATITUDE: '<LATITUDE>',
      LONGITUDE: '<LONGITUDE>',
      POSTAL_CODE: '<POSTALCODE>',
      PROVINCE: '<PROVINCE>',
      PROVINCE_ABBR: '<PROVINCEABBR>',
    },
    Count: {
      FLYER_VIEW_COUNT: '<FLYERVIEWCOUNT>',
      FLYER_VIEW_UNIQUE_COUNT: '<FLYERVIEWUNIQUECOUNT>',
      ITEM_VIEW_COUNT: '<ITEMVIEWCOUNT>',
      ITEM_VIEW_UNIQUE_COUNT: '<ITEMVIEWUNIQUECOUNT>',
      PAGE_VIEW_COUNT: '<PAGEVIEWCOUNT>',
      PAGE_VIEW_UNIQUE_COUNT: '<PAGEVIEWUNIQUECOUNT>',
      SEARCH_COUNT: '<SEARCHCOUNT>', // is not supported
      SEARCH_PRESENTATION_COUNT: '<SEARCHPRESENTATIONCOUNT>', // is not supported
    },
  },
  APPLE_PRIVATE_EMAIL_SUFFIX: '@privaterelay.appleid.com',

  ui: {
    XSMALL_MAX_WIDTH: 319,
    SMALL_MAX_WIDTH: 767,
    MEDIUM_MAX_WIDTH: 1023,
    LARGE_MAX_WIDTH: 1439,
    delayBeforeShowingLoadingIcon: 750,
    centerFirstPage: true,
    centerLastPage: true,
    overflowScroll: false,
    dragScroll: true,
    scrollDebounceDelay: 250,
    resizeDebounceDelay: 17,
    animatePageTransition: true,
    minLoadTimeForImageFade: 10,
    maxFlyerPagesForScrubBar: 55,
    appFooterHeight: 110,
    infoBarHeight: 56,
    gestureRecognition: {
      pan: {
        threshold: 5,
      },
      momentumScroll: {
        dampingFactor: 0.8,
        timeConstant: 325,
        timeConstantSnap: 75,
        minimumVelocityToTrigger: 0.1,
      },
      pinch: {
        threshold: 0,
        endDelay: 115,
      },
    },
    pageSizing: {
      pageWidth: 0.85,
      maxPageWidth: 650,
      maxImageScale: 1.5,
      fitToAspectRatioThreshold: {
        phone: 0.4,
        tablet: 0.4,
        desktop: 0.25,
      },
    },
    bufferPagesToLoad: {
      phone: 2,
      tablet: 3,
      desktop: 4,
    },
    fadePagesOnScroll: {
      enabled: true,
      minimumOpacity: 0.15,
      centerBuffer: 0.25,
    },
    animations: {
      curtainFadeDuration: 300,
      scrollToPageDuration: 300,
    },
    bookshelf: {
      shelfHeight: 34,
    },
  },

  NoteStatus: {
    EMPTY: 'empty',
    EXISTS: 'exists',
    EDITING: 'editing',
    SAVED: 'saved',
  },

  QuantityStatus: {
    EDITING: 'editing',
    SAVED: 'saved',
  },

  Sort: {
    RELEVANCE: 'relevance',
    STORE: 'store',
    PRICE: 'price',
  },

  Locale: {
    suffix: {
      en: 'En',
      'fr-ca': 'Fr',
    },
    language: {
      en: 'EN',
      'fr-ca': 'FR',
    },
    locales: {
      en: ['en'],
      'fr-ca': ['fr-ca'],
    },
  },

  BASE_BACKOFF_INTERVAL: 50,
  UNAUTHORIZED_REQUEST_THRESHOLD: 10,
  MAX_BACKOFF_INTERVAL: 5000,

  analytics: {
    fullFlyerView: {
      minPages: 10,
      minPercent: 0.65,
    },
  },

  LocationMapping: {
    external: {
      city: 'Toronto',
      countryCode: 'CA',
      postalCode: 'M5H2N2',
      regionCode: 'ON',
    },
  },

  ServerRenderAuth: {
    deviceId: 0,
    userId: 0,
    userSessionKey: 'yx1IkakEdvkfDiWWFnmZwoMYYJ7ldSpr8qPX816bRGycfkIcWAPzJnspZq0dAkqV',
    userSessionId: 0,
  },

  LOGGING_HEARTBEAT_INTERVAL: 10000,
  DEFAULT_POSTAL_CODE: 'H1A1A1',
  DEFAULT_DEVICE_TYPE_ID: 5,
  DEFAULT_EMBED_DEVICE_TYPE_ID: 6,
  REQUEST_TIMEOUT: 15000,
  SHORT_REQUEST_TIMEOUT: 3000,
  FLYER_THUMBNAIL_SIZE: 364,
  FLYER_SELECTOR_ENABLED: true,
  SESSION_DURATION: 1800000,
  EV_TRIGGER_TIME: 6000,

  URLS: {
    VIEWER: 'flyers',
    BOOKSHELF: 'flyers',
  },

  MOCK_IP: '64.235.97.162',
};

export default AppConstants;
